import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { HeadertwoComponent } from '../home/headertwo/headertwo.component';
import {FooterComponent} from '../home/footer/footer.component'

export const routes: Routes = [
  {path:'' ,component:HomeComponent},
  {path:'headertwo',component:HeadertwoComponent},
  {path:'footer',component:FooterComponent},
  {path:'about' ,loadChildren: ()=> import('../about/app.AboutModule').then(m=>m.AboutModule)},
  {path:'advids' ,loadChildren: ()=> import('../advids/app.AdvidsModule').then(m=>m.AdvidsModule)},
  {path:'advids-customers' ,loadChildren: ()=> import('../advids-customers/app.AdvisCustomerModule').then(m=>m.AboutAdvidsCustomersModule)},
  {path:'videos' ,loadChildren: ()=> import('../advidsportfolio/app.AdvidsportfolioModule').then(m=>m.AdvidsportfolioModule)},
  {path:'advids-talent' ,loadChildren: ()=> import('../advids-talent/app.AdvidstalentModule').then(m=>m.AdvidstalentModule)},
  {path:'apply-to-resign' ,loadChildren: ()=> import('../apply-to-resign/app.ApplytoresignModule').then(m=>m.ApplytoresignModule)},
  {path:'creators' ,loadChildren: ()=> import('../creators/app.CreaterModule').then(m=>m.CreaterModule)},
  {path:'dialog' ,loadChildren: ()=> import('../dialog/app.DialogModule').then(m=>m.DialogModule)},
  {path:'employment-offer' ,loadChildren: ()=> import('../employment-offer/app.EmployofferModule').then(m=>m.EmploymentofferModule)},
  {path:'getting-started' ,loadChildren: ()=> import('../getting-started/app.GettingstartModule').then(m=>m.GettingstartModule)},
  {path:'how-it-works' ,loadChildren: ()=> import('../how-it-works/app.HowitworksModule').then(m=>m.HowitworksModule)},
  {path:'join-advids' ,loadChildren: ()=> import('../join-advids/app.JoinadvidsModule').then(m=>m.JoinAdvidsModule)},
  {path:'languages' ,loadChildren: ()=> import('../languages/app.LanguagesModule').then(m=>m.LanguagesModule)},
  {path:'letstalk' ,loadChildren: ()=> import('../letstalk/app.LetstalkModule').then(m=>m.LetstalkModule)},
  {path:'making-of-a-3d-video' ,loadChildren: ()=> import('../making-of-a-threed-video/app.Making3dvideoModule').then(m=>m.MakingOf3dVideoModule)},
  {path:'making-of-google-allo-video' ,loadChildren: ()=> import('../making-of-google-allo-video/app.makingallvideoModule').then(m=>m.MakingOfallVideoModule)},
  {path:'online-studio' ,loadChildren: ()=> import('../online-studio/app.OnlinestudioModule').then(m=>m.OnlinestudioModule)},
  {path:'payment-protection' ,loadChildren: ()=> import('../payment-protection/app.PaymentprotectionModule').then(m=>m.PaymentProtectionModule)},
  {path:'sales2accounts' ,loadChildren: ()=> import('../sales2accounts/app.Sales2accountsModule').then(m=>m.sales2accountsModule)},
  {path:'sales2creative' ,loadChildren: ()=> import('../sales2creative/app.Sales2creativeModule').then(m=>m.Sales2creativeModule)},
  {path:'smhead' ,loadChildren: ()=> import('../smhead/app.SmheadModule').then(m=>m.smheadModule)},
  {path:'start' ,loadChildren: ()=> import('../start/app.StartModule').then(m=>m.StartModule)},
  {path:'thankyou' ,loadChildren: ()=> import('../thankyou/app.ThankyouModule').then(m=>m.ThankyouModule)},
  {path:'3d-video-style-guide' ,loadChildren: ()=> import('../threed-video-style-guide/app.ThreedstyleModule').then(m=>m.ThreedVideoStyleGuideModule)},
  {path:'2d-video-style-guide' ,loadChildren: ()=> import('../twod-video-style-guide/app.TwodstyleModule').then(m=>m.TwodVideoStyleGuideModule)},
  {path:'video-pricing' ,loadChildren: ()=> import('../video-pricing/app.VideopricingModule').then(m=>m.VideoPricingModule)},
  {path:'voice-audition' ,loadChildren: ()=> import('../voice-audition/app.VoiceauditionModule').then(m=>m.VoiceAuditionModule)},
  {path:'voice-brief' ,loadChildren: ()=> import('../voice-brief/app.VoicebriefModule').then(m=>m.VoiceBriefModule)},
  {path:'why-advids' ,loadChildren: ()=> import('../why-advids/app.WhyadvidsModule').then(m=>m.WhyAdvidsModule)},
  {path:'dubbing-brief' ,loadChildren: ()=> import('../dubbing-brief/app.DebbugingBrifModule').then(m=>m.DebbugingBrifModule)}
];

@NgModule({
  imports: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
