import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiletstalkService {
  urlE:string;
  
  constructor(private http:HttpClient) {}
    adda(abc){
 this.urlE='https://lead.advids.co/api/LetsTalk/';
 var formData = new FormData();
 formData.append('email', abc.email);
 
 formData.append('message', abc.message);
      return this.http.post(this.urlE,formData);
    }
}
