import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  urlE:string;
  constructor(private http:HttpClient) { }
  getData(){
    return this.http.get("https://lead.advids.co/api/Pricing");
  }
  getUrl(abc){
    // this.urlE='http://pricing.vo-market.net/api/getpricing/'+'?email='+abc.email +'&language='+abc.language+'&duration='+abc.duration+'&durationtype='+abc.durationtype+'&clientip='+abc.clientip;
    // return;
    console.log(abc.duration);
    var formData = new FormData();
      this.urlE='https://lead.advids.co/api/Pricing';
 //return this.http.post(this.urlE,abc);
    formData.append('email', abc.email);
    formData.append('duration', abc.duration);
    formData.append('durationtype', abc.durationtype);
      return this.http.post(this.urlE,formData);
    }
  }

