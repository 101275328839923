import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule,routes } from '../routing/app.mainRouting';
import { AppComponent } from '../app.component';
import { HomeComponent } from './home.component';
import { RouterModule,Routes } from '@angular/router';
import { HeadertwoComponent} from './headertwo/headertwo.component';
import { FooterComponent } from './footer/footer.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiServiceService } from '../api-service.service';
import { ApiletstalkService } from '../apiletstalk.service';
import { HttpClientModule } from '@angular/common/http';
import { from } from 'rxjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeadertwoComponent
   
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    AppRoutingModule,ReactiveFormsModule,
    FormsModule,HttpClientModule,
    BrowserAnimationsModule,MatSelectModule,MatFormFieldModule
  ],
  providers: [ApiServiceService, ApiletstalkService],
  bootstrap: [AppComponent]
})
export class MainModule { }
